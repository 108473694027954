  <template>
    <div class="home position-relative">
      <div class="container p-4">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="text-white m-0 p-0" style="text-shadow: 0 0 4px rgba(0,0,0, .3);">Hello, {{ name }}</h5>
          <router-link to="/profil" class="text-white text-decoration-none d-flex align-items-center gap-2">
            Profil saya
            <div class="bg-secondary d-flex justify-content-center align-items-center rounded-circle bg-white" style="width: 35px;height: 35px;">
              <i class="bi bi-person-fill fs-3 text-secondary"></i>
            </div>
          </router-link>
        </div>
        <!-- <h3 class="fw-light text-white mt-3">Online Loket.</h3> -->
      </div>
      <div class="p-3 rounded bg-white box-info shadow-lg">
        <h6>Saldo Anda.</h6>
        <h1 class="fw-light">{{ formatRupiah(saldo) }}</h1>
        <small class="d-block my-2 text-muted">Mulai transaksi dan cek transaksi anda.</small>
        <router-link to="/topup" class="btn btn-primary me-2"><i class="bi bi-plus-circle-dotted me-2"></i>Topup</router-link>
        <button class="btn btn-outline-primary"><i class="bi bi-clock-history me-2"></i>Riwayat</button>
      </div>
    </div>
    <div class="container p-4" style="margin-top: 100px;">      
      <div class="my-3 d-flex align-items-center">
        <small class="text-muted" style="width: 30%;">Permainan</small>
        <div class="pt-1" style="width: 70%;"><hr></div>
      </div>
      <div class="row">
        <div class="col-4">
          <a href="https://tokoku.org/puzzle  ">
            <img src="../assets/puzzle.webp" class="w-100 rounded shadow border border-3 border-secondary">
          </a>
          <h6 class="text-center mt-2" style="font-size: 9px;">Match puzzle ( chibi )</h6>
        </div>
        <div class="col-4">
          <a href="https://tokoku.org/boar">
            <img src="../assets/boar.webp" class="w-100 rounded shadow border border-3 border-secondary">
          </a>
          <h6 class="text-center mt-2" style="font-size: 9px;">Magic Boar</h6>
        </div>
      </div>
      <br>
      <div class="mb-3 d-flex align-items-center">
        <small class="text-muted" style="width: 25%;">Pembayaran</small>
        <div class="pt-1" style="width: 75%;"><hr></div>
      </div>
      <div class="alert alert-info d-flex gap-3 my-3" role="alert">
        <i class="bi bi-bell-fill"></i>
        <marquee>Gunakan kesempatan emas sebaik mungkin 🔥🔥🔥</marquee>
      </div>
      <div class="row mb-3">
        <div class="col-3">
          <router-link to="pulsa" class="btn w-100 py-3 border-primary text-primary fw-bold fs-3 bg-primary-subtle"><i class="bi bi-phone"></i></router-link>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Pulsa</small>
        </div>
        <div class="col-3">
          <router-link to="/data" class="btn w-100 py-3 border-primary text-primary fw-bold fs-3 bg-primary-subtle"><i class="bi bi-reception-4"></i></router-link>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Paket Data</small>
        </div>
        <div class="col-3">
          <button class="btn w-100 py-3 border-primary text-primary fw-bold fs-3 bg-primary-subtle" @click="openFloatWallet"><i class="bi bi-wallet"></i></button>
          <br>
          <small class="text-center d-block mt-2 fw-bold">E-wallet</small>
        </div>
        <div class="col-3">
          <router-link to="/tf" class="btn w-100 py-3 border-primary text-primary fw-bold fs-3 bg-primary-subtle"><i class="bi bi-send-plus"></i></router-link>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Transfer</small>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <router-link to="/pln" class="btn w-100 py-3 border-primary text-primary fw-bold fs-3 bg-primary-subtle"><i class="bi bi-lightning-fill"></i></router-link>
          <br>
          <small class="text-center d-block mt-2 fw-bold">PLN</small>
        </div>
        <div class="col-3">
          <button class="btn w-100 py-3 border-primary text-primary fw-bold fs-3 bg-primary-subtle" @click="openFloatGame"><i class="bi bi-controller"></i></button>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Games</small>
        </div>
        <div class="col-3">
          <button class="btn w-100 py-3 border-primary text-primary fw-bold fs-3 bg-primary-subtle" @click="openFloat"><i class="bi bi-ticket-perforated-fill"></i></button>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Voucher</small>
        </div>
        <div class="col-3">
          <button class="btn w-100 py-3 border-primary text-white fw-bold fs-3 bg-primary" @click="openFloat"><i class="bi bi-grid-fill"></i></button>
          <br>
          <small class="text-center d-block mt-2 fw-bold">Lainnya</small>
        </div>
      </div>
      <img src="../assets/promo.jpg" class="w-100 mt-4" >
    </div>
    <!-- float game -->
    <div class="bg-float game">
      <div class="box w-100 p-4 bg-white">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="m-0 p-0">Topup Games.</h3>
          <i class="bi bi-x-circle fs-1 text-secondary" @click="closeFloatGame"></i>
        </div>
        <hr>
        <br>
        <div class="row">
          <div class="col-3">
            <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
              <img src="../assets/games/ml.webp" width="105%" class="rounded">
            </button>
            <br>
            <small class="text-center d-block mt-2 fw-bold">MLBB</small>
          </div>
          <div class="col-3">
            <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
              <img src="../assets/games/ff.webp" width="105%" class="rounded">
            </button>
            <br>
            <small class="text-center d-block mt-2 fw-bold">Free fire</small>
          </div>
          <div class="col-3">
            <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
              <img src="../assets/games/pubgm.webp" width="105%" class="rounded">
            </button>
            <br>
            <small class="text-center d-block mt-2 fw-bold">PUBGM</small>
          </div>
          <div class="col-3">
            <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
              <img src="../assets/games/pb.png" width="105%" class="rounded">
            </button>
            <br>
            <small class="text-center d-block mt-2 fw-bold">PB</small>
          </div>
        </div>
        <div class="row my-3">
          <div class="col-3">
            <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
              <img src="../assets/games/valo.png" width="105%" class="rounded">
            </button>
            <br>
            <small class="text-center d-block mt-2 fw-bold">Valorant</small>
          </div>
          <div class="col-3">
            <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
              <img src="../assets/games/cod.webp" width="105%" class="rounded">
            </button>
            <br>
            <small class="text-center d-block mt-2 fw-bold">CODM</small>
          </div>
          <div class="col-3">
            <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
              <img src="../assets/games/garena.jpg" width="105%" class="rounded border border-2">
            </button>
            <br>
            <small class="text-center d-block mt-2 fw-bold">Garena</small>
          </div>
          <div class="col-3">
            <button class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
              <img src="../assets/games/aov.png" width="105%" class="rounded border border-2">
            </button>
            <br>
            <small class="text-center d-block mt-2 fw-bold">AOV</small>
          </div>
        </div>
      </div>
    </div>
    <!-- float maintance -->
    <div class="bg-float float">
      <div class="box w-100 p-4 bg-light">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="m-0 p-0">Dalam Pengembangan.</h3>
          <i class="bi bi-x-circle fs-1 text-secondary" @click="closeFloat"></i>
        </div>
        <hr>
        <br>
        <img src="../assets/maintenance.gif" class="w-100">
      </div>
    </div>
    <!-- float wallet -->
    <div class="bg-float wallet">
      <div class="box w-100 p-4 bg-white">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="m-0 p-0">Topup wallet.</h3>
          <i class="bi bi-x-circle fs-1 text-secondary" @click="closeFloatWallet"></i>
        </div>
        <hr>
        <br>
        <div class="row">
          <div class="col-3">
            <router-link to="dana" class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
              <img src="../assets/wallet/dana.jpeg" width="105%" class="rounded">
            </router-link>
            <br>
            <small class="text-center d-block mt-2 fw-bold">Dana</small>
          </div>
          <div class="col-3">
            <router-link to="ovo" class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
              <img src="../assets/wallet/ovo.jpg" width="105%" class="rounded">
            </router-link>
            <br>
            <small class="text-center d-block mt-2 fw-bold">Ovo</small>
          </div>
          <div class="col-3">
            <router-link to="gopay" class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
              <img src="../assets/wallet/gopay.png" width="105%" class="rounded">
            </router-link>
            <br>
            <small class="text-center d-block mt-2 fw-bold">Gopay</small>
          </div>
          <div class="col-3">
            <router-link to="spay" class="btn w-100 py-3 overflow-hidden text-primary fw-bold fs-3" style="height: 73.5px;">
              <img src="../assets/wallet/sopi.jpeg" width="105%" class="rounded">
            </router-link>
            <br>
            <small class="text-center d-block mt-2 fw-bold">Spay</small>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import axios from 'axios';
  import Cookies from 'js-cookie';

  export default {
    name: 'HomeView',
    methods: {
      openFloatGame(){
        document.querySelector(".game").classList.add("on-bg-float");
      },
      closeFloatGame(){
        document.querySelector(".game").classList.remove("on-bg-float");
      },
      openFloat(){
        document.querySelector(".float").classList.add("on-bg-float");
      },
      closeFloat(){
        document.querySelector(".float").classList.remove("on-bg-float");
      },
      openFloatWallet(){
        document.querySelector(".wallet").classList.add("on-bg-float");
      },
      closeFloatWallet(){
        document.querySelector(".wallet").classList.remove("on-bg-float");
      },
      formatRupiah(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value);
      },
    },
    data(){
      return {
        name: "",
        saldo: 0
      }
    },
    mounted() {
      const token = Cookies.get('auth_token');
      const userId = Cookies.get('user_id');
      if (token == null || userId == null) {
        this.$router.push("/");
      }

      axios.get("https://api.tokoku.org/public/api/users/" + userId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
          console.log(response.data);
          this.name = response.data.name;
          this.saldo = response.data.saldo;
        })
    },
  }
  </script>

  <style>
    .home {
      background-image: url(../assets/bg.jpg);
      background-size: 110%;
      background-position: center;
      width: 100%;
      height: 180px;
      border-radius: 0 0 15px 15px;
    }

    .box-info {
      width: 90%;
      position: absolute;
      left: 5%;
      top: 13vh;
    }

    .bg-float {
      display: none;
      position: fixed;
      background-color: rgba(0, 0, 0, 0.75);
      z-index: 9;
      top: 0;bottom: 0;left: 0;right: 0;
    }

    .on-bg-float {
      display: block;
    }

    .box {
      position: absolute;
      bottom: 0;
      border-radius: 20px 20px 0 0 ;
      height: 70dvh;
    }
  </style>
