<template>
  <div class="login bg-white p-3 pt-5">
    <h3 class="fw-bold">Welcome to PPOB</h3>
    <small>Login untuk masuk ke halaman utama</small>
    <img src="../assets/3d.jpg" width="100%">
    <form @submit.prevent="loginUser">
      <div class="mb-3">
        <label for="email" class="form-label">Email Anda</label>
        <input type="email" class="form-control" id="email" v-model="form.email">
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Password</label>
        <input type="password" class="form-control" id="password" v-model="form.password">
      </div>
      <button type="submit" class="btn w-100 btn-lg btn-primary">Submit</button>
    </form>
    <p class="mt-3">Belum Punya akun? <router-link to="/regis">Daftar</router-link></p>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

export default {
  name: 'LoginView',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
    };
  },
  methods: {
    async loginUser() {
      try {
        const response = await axios.post('https://api.tokoku.org/public/api/login', this.form);
        
        // Simpan token dan ID user ke cookies
        Cookies.set('auth_token', response.data.access_token, { expires: 7 }); // Token valid 7 hari
        Cookies.set('user_id', response.data.user.id, { expires: 7 });

        // Tampilkan pesan sukses dan arahkan ke dashboard
        Swal.fire({
          title: 'Login Successful!',
          text: 'You are now logged in.',
          icon: 'success',
          confirmButtonText: 'Go to Dashboard',
        }).then(() => {
          this.$router.push('/dashboard');
        });
      } catch (error) {
        Swal.fire({
          title: 'Login Failed',
          text: 'Invalid email or password.',
          icon: 'error',
          confirmButtonText: 'Try Again',
        });
      }
    },
  },
};
</script>

<style>
  .login {
    width: 100%;
    height: 100dvh;
  }
</style>
