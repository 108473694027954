<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> 
  </nav> -->
  <div class="screen">
    <router-view/>
  </div>
</template>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .screen {
    width: 420px;
    min-height: 100dvh;
    margin: auto;
    border-right: 3px solid gainsboro;
    border-left: 3px solid gainsboro;
  }
}
</style>
